import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
import { getGood, getGoods } from "../../api/fetch";
import SearchBar from "material-ui-search-bar";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import LazyLoadImage from "react-lazy-load";
import LazyLoad from "react-lazy-load";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';

const textFieldStyle = {
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "5px",
  color: "",
};

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
}));

const Image = styled("img")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
}));

function strtime(value) {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  if (hours === 0 && minutes === 0) return seconds + " sec";
  if (hours === 0 && seconds === 0) return minutes + " min";
  if (seconds === 0 && minutes === 0) return hours + " hr";
  if (hours === 0) return minutes + " min" + seconds + " sec";
  if (seconds === 0) return hours + " hr " + minutes + " min";
  return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    const aWithoutNeeds = { ...a[0], needs: null };
    const bWithoutNeeds = { ...b[0], needs: null };
    return aWithoutNeeds.needs === null
      ? -1
      : bWithoutNeeds.needs === null
      ? 1
      : comparator(aWithoutNeeds, bWithoutNeeds);
    //return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "image",
    disableSorting: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "level",
    numeric: true,
    disablePadding: false,
    label: "Level",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "price",
  },
  {
    id: "time",
    numeric: true,
    disablePadding: false,
    label: "time",
  },
  {
    id: "xp",
    numeric: true,
    disablePadding: false,
    label: "xp",
  },
  {
    id: "source",
    numeric: false,
    disablePadding: false,
    label: "source",
  },
  // {
  //   id: "needs",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "needs",
  // },
  {
    id: "needs",
    numeric: true,
    disablePadding: false,
    label: "needs",
    disableSorting: true,
  },
];

function EnhancedTableHead(props) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [rows, setData] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("level");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(400);
  const navigate = useNavigate();

  useEffect(() => {
    setData(props.goods);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "80%",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        position: "relative",
        alignItems: "center",
        marginTop:5
      }}
    >
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          backgroundColor: "rgba(240, 248, 255, 0.95)",
        }}
      >
        <Box sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <LazyLoad once>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={() => navigate(`/goodsList/${encodeURIComponent(row.name.toLowerCase().replace(/ /g, '-'))}`)}
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: '#f5f5f5 !important'
                            }
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                        >
                          <TableCell align="left">
                            <LazyLoad>
                              <img
                                alt=""
                                src={row.image}
                                style={{ width: "40px", height: "40px" }}
                                effect="blur"
                              />
                            </LazyLoad>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.level}</TableCell>
                          <TableCell align="left">{row.price}</TableCell>
                          <TableCell align="left">
                            {strtime(row.time)}
                          </TableCell>
                          <TableCell align="left">{row.xp}</TableCell>
                          <TableCell align="left">{row.source}</TableCell>
                          <TableCell align="left">
                            <div style={{ display: "flex" }}>
                              {row.needs.map((item, index) => (
                                <Item
                                  key={index}
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                >
                                  <Image
                                    src={props.goodsImages[item.name]}
                                    alt={item.name}
                                  />
                                  <Typography variant="subtitle1">
                                    {item.quantity}
                                  </Typography>
                                </Item>
                              ))}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </LazyLoad>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}
