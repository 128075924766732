import React from "react";
import { useEffect, useState } from "react";
import DispData from "../components/GoodsListDisplay/dispdata";
import MediaCard from "../components/Card/card";
import { getGoods } from "../api/fetch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SearchBar from "material-ui-search-bar";
import ReactGA from "react-ga4";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EnhancedTable from "../components/GoodsListDisplay/goodsTable";
import { Constants } from "../config/constants";
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';
import { goods } from '../goods'
import { HD_images } from '../HD_images';
import { productionBuildingsData } from "../productionBuildings";
import  DispProductionBuildingsData  from '../components/ProductionBuildingsListDisplay/dispProductionBuildingsData'

import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Typography,
  Button,
  Menu,
  Box
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
  slider: {
    "& .MuiSlider-rail": {
      backgroundColor: "#C4C4C4",
    },
    "& .MuiSlider-track": {
      backgroundColor: "#3F51B5",
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "#3F51B5",
    },
  },
}));

const theme = createTheme();

const ProductionBuildingsList = () => {
  const classes = useStyles();

  const handleSortChange = (event) => {
    const [field, order] = event.target.value.split("-");
    setSort({ field, order });
  };

  const [data, setData] = useState([]);
  const [orgRows, setOrgRows] = useState([]);
  const [searched, setSearched] = React.useState("");
  const [sort, setSort] = useState({ field: "name", order: "asc" });
  const [checkedSources, setCheckedSources] = useState([]);
  const sourceSet = new Set();
  orgRows.forEach((row) => sourceSet.add(row.source));
  const [anchorEl, setAnchorEl] = useState(null);
  const [goodsImages, setGoodsImages] = useState({});
  const [levelRange, setLevelRange] = useState([1, 200]);
  const [isTable, setIsTable] = useState(false);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({
      hitType: "pageview",
      page: "/productionBuildingsList",
      title: "Production Buildings List Page Visit",
    });
    let data = productionBuildingsData;

    setData(data);
    setOrgRows(data);
    getImagesData();
  }, []);

  useEffect(() => {
    const newGoodsImages = {};
    data.map(item => {
      const { name, image } = item;
      newGoodsImages[name] = image;
    });
    setGoodsImages(prevState => ({ ...prevState, ...newGoodsImages }));
  }, [data]);

  const getImagesData = () => {
    const newGoodsImages = HD_images;
      setGoodsImages(prevState => ({ ...prevState, ...newGoodsImages }));
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = orgRows.filter((row) => {
      const searchLower = searchedVal.toLowerCase();
      return (
        row.name.toLowerCase().includes(searchLower)
      );
    });
    setData(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const sortCards = (cards) => {
    const { field, order } = sort;
    const compare = (a, b) => {
      let aValue, bValue;
      switch (field) {
        case "name":
          aValue = a[field].toLowerCase();
          bValue = b[field].toLowerCase();
          break;
        // case "level":
        // case "price":
        case "time":
        case "xpWhenBuilt":
          aValue = Number(a[field]);
          bValue = Number(b[field]);
          break;
        default:
          aValue = a[field];
          bValue = b[field];
          break;
      }
      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    };
    return [...cards].sort(compare);
  };

  const handleCheckedSource = (event) => {
    const source = event.target.value;
    if (event.target.checked) {
      setCheckedSources([...checkedSources, source]);
    } else {
      setCheckedSources(checkedSources.filter((src) => src !== source));
    }
  };

  

  const handleLevelRangeChange = (event, newValue) => {
    console.log(goodsImages);
    setLevelRange(newValue);
  };

  const filterCards = (cards) => {
    let result = cards;
    if (checkedSources.length > 0) {
      result = result.filter((card) => checkedSources.includes(card.source));
    }
    result = result.filter(
      (card) => card.level >= levelRange[0] && card.level <= levelRange[1]
    );
    return result;
  };

  const handleToggle = () => {
    setIsTable(!isTable);
  };

  const sortedSources = Array.from(sourceSet).sort();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Hay Day Production Buildings List</title>
        <meta name="description" content="Browse the list of Production buildings available in Hay Day Calculator. Find detailed information about each good, including its name, level, price, production time, XP value, and source. Use the search bar and filters to find specific Production buildings. View the Production buildings as cards or in a table format. Enhance your Hay Day gaming experience with Hay Day Calculator." />
        <meta name="keywords" content="Hay Day Production buildings List, Production buildings List, Hay day foods, Hay day items, Hay day Production buildings, Hay day, Hay day tools, Hay day Production buildings details description " />
        <meta name="robots" content="index, follow" />
        </Helmet>
      <div style={{ marginBottom: "100" }}>
        <CssBaseline />
        <AdSense />
        <Grid
          container
          spacing={2}
          alignItems="center"
          marginTop={3}
          justifyContent="space-evenly"
        >
          <Grid item>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              style={{
                border: "2px solid black",
                width: "400px",
                height: "40px",
                fontSize: "14px",
                borderRadius: "50px",
                margin: "0 auto",
              }}
            />
          </Grid>
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="sort-by-label">Sort by</InputLabel>
              <Select
                labelId="sort-by-label"
                id="sort-by-select"
                value={`${sort.field}-${sort.order}`}
                onChange={handleSortChange}
                label="Sort by"
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
                {/* <MenuItem value="level-asc">Level (Low to High)</MenuItem>
                <MenuItem value="level-desc">Level (High to Low)</MenuItem>
                <MenuItem value="price-asc">Price (Low to High)</MenuItem>
                <MenuItem value="price-desc">Price (High to Low)</MenuItem> */}
                <MenuItem value="time-asc">Time (Low to High)</MenuItem>
                <MenuItem value="time-desc">Time (High to Low)</MenuItem>
                <MenuItem value="xpWhenBuilt-asc">XP (Low to High)</MenuItem>
                <MenuItem value="xpWhenBuilt-desc">XP (High to Low)</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item>
            <Typography id="level-range-slider" gutterBottom>
              Level Range:
            </Typography>
            <Slider
              className={classes.slider}
              value={levelRange}
              onChange={handleLevelRangeChange}
              valueLabelDisplay="auto"
              aria-labelledby="level-range-slider"
              min={1}
              max={200}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2">{levelRange[0]}</Typography>
              <Typography variant="body2">{levelRange[1]}</Typography>
            </Box>
          </Grid> */}

          

          {/* <Grid item>
          <Button
  onClick={handleToggle}
  sx={{
    borderRadius: "20px",
    padding: "8px 16px",
    backgroundColor: "#1876d2",
    color: "#fff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1876d2",
      color: "#fff",
    },
    "&.active": {
      backgroundColor: "#2196f3",
      color: "#fff",
    },
  }}
  className={isTable ? "" : "active"}
>
  {isTable ? "View Cards" : "View Table"}
</Button>
          </Grid> */}
        </Grid>



        {isTable ? (
          //<EnhancedTable goods={filterCards(sortCards(data))} goodsImages={goodsImages} />
          <DispProductionBuildingsData
          productionBuildings={filterCards(sortCards(data))}
            goodsImages={goodsImages}
          />
        ) : (
          <DispProductionBuildingsData
          productionBuildings={filterCards(sortCards(data))}
            goodsImages={goodsImages}
          />
        )}


      </div>
    </ThemeProvider>
  );
};

export default ProductionBuildingsList;
