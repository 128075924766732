import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ListItemIcon } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import GoogleTranslate from "../googleTranslate"
import { useState, useEffect } from 'react';
import { auth } from '../../config/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import GoogleLoginButton from '../GoogleLoginButton';
import LogoutButton from '../LogoutButton';


const pages = [
  {
      name: 'Home',
      urlLink: '/home'
  },
  {
    name: 'Goods List',
    urlLink: '/goodsList'
  },
  {
    name: 'Town Calculator',
    urlLink: '/townToolsCalculator'
  },
  {
    name: 'Tackle Box Calculator',
    urlLink: '/tackleBoxSizeCalculator'
  },
  {
      name: 'Price Calculator',
      urlLink: '/priceCalculator'
  },
  {
    name: 'Silo Calculator',
    urlLink: '/siloSizeCalculator'
  },
  {
    name: 'Barn Size Calculator',
    urlLink: '/barnSizeCalculator'
},{
  name: 'Food List Maker',
  urlLink: '/foodListMaker'
},
{
  name: 'Production Buildings List',
  urlLink: '/productionBuildingsList'
}
]

const calculatorPages = [
  
  {
      name: 'Deco Cost Calculator',
      urlLink: '/decoCostCalculator'
  },
  {
    name: 'Goods Image Maker',
    urlLink: '/imageMaker'
  },
  {
    name: 'Download Hay Day APK',
    urlLink: '/download/hay-day-apk'
  },
  {
    name: 'Contact Us',
    urlLink: '/contactUs'
  }
  
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElCalculator, setAnchorElCalculator] = React.useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleLoginSuccess = (user) => {
    console.log('Logged in user:', user);
  };

  const handleLogout = () => {
    setUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenCalculatorMenu = (event) => {
    setAnchorElCalculator(event.currentTarget);
  };

  const handleCloseCalculatorMenu = () => {
    setAnchorElCalculator(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ minHeight: 80, py: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          {/* Logo Section */}
          <Typography
  variant="h5"
  noWrap
  component={Link}
  to="/home"
  sx={{
    mr: 4, 
    display: { xs: 'none', md: 'flex' },
    
    fontWeight: 400,
    letterSpacing: '.15rem',
    color: 'inherit',
    textDecoration: 'none',
    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
    fontSize: '1.6rem',
    whiteSpace: 'nowrap',
    minWidth: '140px' // Set a minimum width
  }}
>
  HAY DAY
</Typography>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
            edge="end"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu} component={Link} to={page.urlLink}>
                <Typography textAlign="center" href={page.urlLink} >{page.name}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={handleOpenCalculatorMenu}>
  <Typography textAlign="center">Other Tools</Typography>
  <ListItemIcon>
    <ArrowRightIcon />
  </ListItemIcon>
</MenuItem>
            <Menu
              id="calculators-menu"
              anchorEl={anchorElCalculator}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElCalculator)}
              onClose={handleCloseCalculatorMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {calculatorPages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseCalculatorMenu} component={Link} to={page.urlLink}>
                  <Typography textAlign="center" href={page.urlLink} >{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Menu>
        </Box>
  
        <Box sx={{ 
            flexGrow: 1, 
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1
          }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.urlLink}
                sx={{
                  color: 'white',
                  px: 1.5,
                  py: 1,
                  fontSize: '0.9rem',
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    transform: 'translateY(-2px)'
                  },
                  transition: 'all 0.2s ease'
                }}
              >
                {page.name}
              </Button>
            ))}
           <Button
              onClick={handleOpenCalculatorMenu}
              sx={{
                color: 'white',
                px: 1.5,
                py: 1,
                fontSize: '0.9rem',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)'
                }
              }}
            >
              Other Tools <ArrowDropDownIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Button>


          <Menu
            id="calculators-menu-md"
            anchorEl={anchorElCalculator}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElCalculator)}
            onClose={handleCloseCalculatorMenu}
            sx={{
              display: { xs: 'none', md: 'block' },
            }}
          >
            {calculatorPages.map((page) => (
                <MenuItem 
                  key={page.name} 
                  component={Link} 
                  to={page.urlLink}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.light'
                    }
                  }}
                >
                  <Typography variant="body2">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
       {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
                

                 {/* Auth buttons container */}
                 <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 2,
            ml: 2,
            backgroundColor: 'rgba(255,255,255,0.1)',
            px: 2,
            borderRadius: 2,
            backdropFilter: 'blur(8px)'
          }}>
            <GoogleTranslate />
            
            {/* Updated Auth Section */}
            {user ? (
              <>
                <Tooltip title="Account settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar 
                      alt={user.displayName} 
                      src={user.photoURL}
                      sx={{ 
                        width: 36, 
                        height: 36,
                        bgcolor: 'primary.main',
                        '&:hover': {
                          transform: 'scale(1.1)',
                          transition: 'transform 0.2s'
                        }
                      }}
                    >
                      {user.displayName?.[0]}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                
                <Menu
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    sx: {
                      mt: 1.5,
                      minWidth: 180,
                      borderRadius: '8px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                    }
                  }}
                >
                  <MenuItem disabled>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {user.displayName}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography>Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <GoogleLoginButton 
                onSuccess={handleLoginSuccess} 
                onError={(error) => console.error(error)}
              />
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;