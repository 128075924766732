// src/components/GoogleLoginButton.js
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { signInWithGoogle } from '../services/auth';
import { createUserDocument, createLogEntry } from '../services/dbService'; // Add this import

const GoogleLoginButton = ({ onSuccess, onError }) => {
  const handleLogin = async () => {
    try {
      const user = await signInWithGoogle();
      
      // Create/update user document
      await createUserDocument(user);
      
      // Create login log
      await createLogEntry(user.uid, 'login');
      
      onSuccess(user);
    } catch (error) {
      onError(error.message);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleLogin}
      fullWidth
      sx={{
        backgroundColor: '#4285F4',
        color: 'white',
        '&:hover': {
          backgroundColor: '#357ABD',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
        },
        transition: 'all 0.3s ease',
        py: 1.5,
        borderRadius: '8px'
      }}
      startIcon={<GoogleIcon />}
    >
      Login
    </Button>
  );
};

export default GoogleLoginButton;