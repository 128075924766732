const HD_images = {
    "barn": "https://static.wikia.nocookie.net/hayday/images/f/f8/Barn.png",
    "silo": "https://static.wikia.nocookie.net/hayday/images/5/5f/Silo.png",
    "bolt": "https://static.wikia.nocookie.net/hayday/images/8/8e/Bolt.png",
    "duct tape": "https://static.wikia.nocookie.net/hayday/images/4/4e/Duct_Tape.png",
    "plank": "https://static.wikia.nocookie.net/hayday/images/0/0d/Plank.png",
    "nail": "https://static.wikia.nocookie.net/hayday/images/4/40/Nail.png",
    "screw": "https://static.wikia.nocookie.net/hayday/images/5/59/Screw.png",
    "wood panel": "https://static.wikia.nocookie.net/hayday/images/0/02/Wood_Panel.png",
    "brick": "https://static.wikia.nocookie.net/hayday/images/5/58/Brick.png",
    "hammer": "https://static.wikia.nocookie.net/hayday/images/8/81/Hammer.png",
    "hand drill": "https://static.wikia.nocookie.net/hayday/images/d/d4/Hand_Drill.png",
    "paint bucket": "https://static.wikia.nocookie.net/hayday/images/9/92/Paint_Bucket.png",
    "stone block": "https://static.wikia.nocookie.net/hayday/images/3/37/Stone_Block.png",
    "tar bucket": "https://static.wikia.nocookie.net/hayday/images/f/f8/Tar_Bucket.png",
    "refined coal": "https://static.wikia.nocookie.net/hayday/images/b/b5/Refined_Coal.png",
    "iron bar": "https://static.wikia.nocookie.net/hayday/images/6/6c/Iron_Bar.png",
    "gold bar": "https://static.wikia.nocookie.net/hayday/images/4/4e/Gold_Bar.png",
    "silver bar": "https://static.wikia.nocookie.net/hayday/images/9/94/Silver_Bar.png",
    "platinum bar": "https://static.wikia.nocookie.net/hayday/images/d/d2/Platinum_Bar.png",
    "green voucher": "https://static.wikia.nocookie.net/hayday/images/5/51/Green_Voucher.png",
    "blue voucher": "https://static.wikia.nocookie.net/hayday/images/2/21/Blue_Voucher.png",
    "purple voucher": "https://static.wikia.nocookie.net/hayday/images/6/66/Purple_Voucher.png",
    "gold voucher": "https://static.wikia.nocookie.net/hayday/images/6/6e/Golden_Voucher.png",
    "diamond": "https://static.wikia.nocookie.net/hayday/images/e/ea/Diamond.png",
    "lobster": "https://static.wikia.nocookie.net/hayday/images/9/9f/Lobster.png",
    "duck": "https://static.wikia.nocookie.net/hayday/images/3/38/Duck.png"
};
module.exports = { HD_images };