const productionBuildingsData = [
  {
    name: "bakery",
    level: [2],
    price: [20],
    time: 10, // “10 sec” → 10 seconds
    xpWhenBuilt: 3,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/4/47/Bakery.png"
  },
  {
    name: "feed mill", // removed " x2" and lowercased
    level: [3,12], // extracted from "3 (1st)"
    price: [5,3200],
    time: 40, // “40 sec”
    xpWhenBuilt: 4,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/d/db/Feed_Mill.png"
  },
  {
    name: "dairy",
    level: [6],
    price: [50],
    time: 7200, // “2 h” → 2 * 3600
    xpWhenBuilt: 8,
    size: [4, 4],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/e3/Dairy.png"
  },
  {
    name: "sugar mill",
    level: [7,76],
    price: [350,200000],
    time: 21600, // 6h → 6 * 3600
    xpWhenBuilt: 13,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/8/8a/Sugar_Mill.png"
  },
  {
    name: "popcorn pot",
    level: [8],
    price: [650],
    time: 28800, // 8h
    xpWhenBuilt: 14,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/3/3c/Popcorn_Pot.png"
  },
  {
    name: "bbq grill",
    level: [9],
    price: [730],
    time: 28800,
    xpWhenBuilt: 14,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/9b/BBQ_Grill.png"
  },
  {
    name: "pie oven",
    level: [14],
    price: [2200],
    time: 43200, // 12h = 12 * 3600
    xpWhenBuilt: 17,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/eb/Pie_Oven.png"
  },
  {
    name: "loom",
    level: [17],
    price: [3200],
    time: 86400, // 1 day = 86400 sec
    xpWhenBuilt: 19,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/3/3b/Loom.png"
  },
  {
    name: "sewing machine",
    level: [19],
    price: [4500],
    time: 72000, // 20h = 20 * 3600
    xpWhenBuilt: 19,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/e0/Sewing_Machine.png"
  },
  {
    name: "cake oven",
    level: [21],
    price: [12100],
    time: 86400,
    xpWhenBuilt: 24,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/95/Cake_Oven.png"
  },
  {
    name: "mine",
    level: [24],
    price: [21000],
    time: 126000, // 1d 11h = 86400 + 39600
    xpWhenBuilt: 28,
    size: null, // "N/A" → no size
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/a/a0/Mine.png"
  },
  {
    name: "smelter",
    level: [24,24,24,24,24],
    price: [12500,22000,31500,41000,50500],
    time: 64800, // 18h
    xpWhenBuilt: 21,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/4/45/Smelter.png"
  },
  {
    name: "juice press",
    level: [26],
    price: [31000],
    time: 115200, // 1d 8h = 86400 + 28800
    xpWhenBuilt: 27,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/9c/Juice_Press.png"
  },
  {
    name: "lure workbench",
    level: [27],
    price: [null], // "N/A" becomes null
    time: null,
    xpWhenBuilt: null,
    size: null,
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/0/0a/Lure_Workbench.png"
  },
  {
    name: "ice cream maker",
    level: [29],
    price: [38000],
    time: 111600, // 1d 7h = 86400 + 25200
    xpWhenBuilt: 27,
    size: [3, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/c/cf/Ice_Cream_Maker.png"
  },
  {
    name: "net maker",
    level: [30],
    price: [28000],
    time: 172800, // 2d = 2 * 86400
    xpWhenBuilt: 32,
    size: null, // "N/A"
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/4/49/Net_Maker.png"
  },
  {
    name: "jam maker",
    level: [35],
    price: [59000],
    time: 129600, // 1d 12h = 86400 + 43200
    xpWhenBuilt: 28,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/e5/Jam_Maker.png"
  },
  {
    name: "jeweler",
    level: [38],
    price: [68000],
    time: 129600,
    xpWhenBuilt: 28,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/c/c0/Jeweler.png"
  },
  {
    name: "honey extractor",
    level: [39],
    price: [35000],
    time: 86400,
    xpWhenBuilt: 24,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/2/20/Honey_Extractor.png"
  },
  {
    name: "coffee kiosk",
    level: [42],
    price: [75000],
    time: 126000, // 1d 11h
    xpWhenBuilt: 35,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/92/Coffee_Kiosk.png"
  },
  {
    name: "lobster pool",
    level: [44],
    price: [80000],
    time: 172800, // 2d
    xpWhenBuilt: 32,
    size: null,
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/97/Lobster_Pool_Stage_6.png"
  },
  {
    name: "soup kitchen",
    level: [46],
    price: [115000],
    time: 129600,
    xpWhenBuilt: 28,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/7/7e/Soup_Kitchen.png"
  },
  {
    name: "candle maker",
    level: [48],
    price: [118000],
    time: 136800, // 1d 14h = 86400 + 50400
    xpWhenBuilt: 24,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/5/5c/Candle_Maker.png"
  },
  {
    name: "flower shop",
    level: [49],
    price: [120000],
    time: 140400, // 1d 15h = 86400 + 54000
    xpWhenBuilt: 30,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/a/a6/Flower_Shop.png"
  },
  {
    name: "duck salon",
    level: [50],
    price: [90000],
    time: 190800, // 2d 5h = 172800 + 18000
    xpWhenBuilt: 34,
    size: null,
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/6/67/Duck_Salon_Stage6.png"
  },
  {
    name: "candy machine",
    level: [51],
    price: [120000],
    time: 86400,
    xpWhenBuilt: 27,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/2/2e/Candy_Machine.png"
  },
  {
    name: "sauce maker",
    level: [54],
    price: [135000],
    time: 144000, // 1d 16h = 86400 + 57600
    xpWhenBuilt: 30,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/8/86/Sauce_Maker.png"
  },
  {
    name: "sushi bar",
    level: [56],
    price: [150000],
    time: 158400, // 1d 20h = 86400 + 72000
    xpWhenBuilt: 38,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/b/b3/Sushi_Bar.png"
  },
  {
    name: "salad bar",
    level: [58],
    price: [165000],
    time: 172800,
    xpWhenBuilt: 32,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/a/a5/Salad_Bar.png"
  },
  {
    name: "sandwich bar",
    level: [61],
    price: [180000],
    time: 187200, // 2d 4h = 172800 + 14400
    xpWhenBuilt: 34,
    size: [3, 3],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/b/b0/Sandwich_Bar.png"
  },
  {
    name: "smoothie mixer",
    level: [64],
    price: [220000],
    time: 259200, // 3d
    xpWhenBuilt: 39,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/1/12/Smoothie_Mixer.png"
  },
  {
    name: "pasta maker",
    level: [67],
    price: [410000],
    time: 284400, // 3d 7h = 259200 + 25200
    xpWhenBuilt: 43,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/3/38/Pasta_Maker.png"
  },
  {
    name: "essential oils lab",
    level: [68],
    price: [85000],
    time: 284400, // 3d 7h
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/a/a7/Essential_Oils_Lab.png"
  },
  {
    name: "wok kitchen",
    level: [69],
    price: [350000],
    time: 288000, // 3d 8h = 259200 + 28800
    xpWhenBuilt: 41,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/c/c7/Wok_Kitchen.png"
  },
  {
    name: "hat maker",
    level: [70],
    price: [260000],
    time: 270000, // 3d 3h = 259200 + 10800
    xpWhenBuilt: 40,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/ea/Hat_Maker.png"
  },
  {
    name: "pasta kitchen",
    level: [72],
    price: [550000],
    time: 302400, // 3d 12h = 259200 + 43200
    xpWhenBuilt: 42,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/6/6b/Pasta_Kitchen.png"
  },
  {
    name: "hot dog stand",
    level: [75],
    price: [650000],
    time: 316800, // 3d 16h = 259200 + 57600
    xpWhenBuilt: 41,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/b/b7/Hot_Dog_Stand.png"
  },
  {
    name: "donut maker",
    level: [76],
    price: [680000],
    time: 324000, // 3d 18h = 259200 + 64800
    xpWhenBuilt: 42,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/a/ac/Donut_Maker.png"
  },
  {
    name: "taco kitchen",
    level: [77],
    price: [700000],
    time: 324000, // same as above
    xpWhenBuilt: 43,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/6/65/Taco_Kitchen.png"
  },
  {
    name: "omelet station",
    level: [77],
    price: [600000],
    time: 288000, // 3d 8h
    xpWhenBuilt: 41,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/7/7d/Omelet_Station.png"
  },
  {
    name: "tea stand",
    level: [80],
    price: [750000],
    time: 342000, // 3d 23h = 259200 + 82800
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/7/74/Tea_Stand.png"
  },
  {
    name: "fondue pot",
    level: [81],
    price: [800000],
    time: 345600, // 4d
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/1/10/Fondue_Pot.png"
  },
  {
    name: "bath kiosk",
    level: [84],
    price: [850000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/1/14/Bath_Kiosk.png"
  },
  {
    name: "deep fryer",
    level: [87],
    price: [900000],
    time: 342000, // 3d 23h
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/0/05/Deep_Fryer.png"
  },
  {
    name: "preservation station",
    level: [91],
    price: [950000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/5/5d/Preservation_Station.png"
  },
  {
    name: "pottery studio",
    level: [94],
    price: [1000000],
    time: 345600,
    xpWhenBuilt: 46,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/e/e3/Pottery_Studio.png"
  },
  {
    name: "fudge shop",
    level: [99],
    price: [1050000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/2/27/Fudge_Shop.png"
  },
  {
    name: "yogurt maker",
    level: [103],
    price: [1100000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/5/55/Yogurt_Maker.png"
  },
  {
    name: "stew pot",
    level: [106],
    price: [1150000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/9f/Stew_Pot.png"
  },
  {
    name: "cupcake maker",
    level: [109],
    price: [1200000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/9/94/Cupcake_Maker.png"
  },
  {
    name: "perfumerie",
    level: [110],
    price: [1000000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/5/54/Perfumerie.png"
  },
  {
    name: "waffle maker",
    level: [114],
    price: [1250000],
    time: 345600, // "4d" → 4 * 86400
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/2/2b/Waffle_Maker.png"
  },
  {
    name: "porridge bar",
    level: [119],
    price: [1300000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/5/5a/Porridge_Bar.png"
  },
  {
    name: "milkshake bar",
    level: [124],
    price: [1350000],
    time: 345600,
    xpWhenBuilt: 44,
    size: [2, 2],
    slots: 2,
    image: "https://static.wikia.nocookie.net/hayday/images/4/4a/Milkshake_Bar.png"
  }
];

module.exports = { productionBuildingsData };
