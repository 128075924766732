import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import AdSense from '../components/googleAds';
import { useState, useEffect } from "react";

const theme = createTheme();

const TackleBoxSizeCalculator = () => {
  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/tackleBoxSizeCalculator", title: "Tackle Box Size Calculator Page Visit" });
  }, []);

  const [ini, setIni] = useState(20);
  const [fin, setFin] = useState(25);
  const [quantity, setQuantity] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (event) => {
    setIsError(false);
    event.preventDefault();
    let x = calculate(ini, fin);
    setShowImages(true);
    setQuantity(x);
  };

  function calculate(ini, fin) {
    ini = parseInt(ini, 10);
    fin = parseInt(fin, 10);
    
    // Validate inputs
    if (isNaN(ini) || isNaN(fin) || 
        ini < 20 || fin > 1000 || 
        (ini - 20) % 5 !== 0 || 
        (fin - 20) % 5 !== 0 || 
        fin <= ini) {
        setIsError(true);
        return -1;
    }

    // Calculate levels (level 1 = 20-25)
    const initialLevel = (ini - 20) / 5;
    const finalLevel = (fin - 20) / 5;
    const upgradesNeeded = finalLevel - initialLevel;

    // Calculate total materials using: sum from (initialLevel+1) to finalLevel
    const total = (finalLevel * (finalLevel + 1)) / 2 - (initialLevel * (initialLevel + 1)) / 2;
    
    return total;
}

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Hay Day Tackle Box Size Calculator</title>
        <meta name="description" content="Calculate the tools required to upgrade your Tackle Box in Hay Day with the Tackle Box Size Calculator. Determine the number of Nails, Screws, and Wood Panels, needed to expand your storage capacity. Plan your upgrades efficiently and optimize your resource management in Hay Day." />
        <meta name="keywords" content="Hay Day Tackle Box calculator, hay day Tackle Box size calculator, hay day Tackle Box tools, hay day Tackle Box tools calculator, hay day calculator, hay day discord" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Container component="main" maxWidth="xs" sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '32px',
        marginTop: '40px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        marginBottom: 4
      }}>
        <CssBaseline />
        <AdSense />
        
        <Box sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: 4,
          padding: 3,
          marginBottom: 4,
          textAlign: 'center',
          border: '1px solid #e0e0e0'
        }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <img 
                src="https://static.wikia.nocookie.net/hayday/images/2/2c/Tackle_Box.png" 
                alt="Hay Day Tackle Box" 
                style={{ 
                  width: '100%', 
                  maxWidth: '200px', 
                  height: 'auto',
                  filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" sx={{
                fontSize: 28,
                fontWeight: 700,
                color: '#2e7d32',
                marginBottom: 1,
                fontFamily: '"Luckiest Guy", cursive'
              }}>
                Tackle Box Calculator
              </Typography>
              <Typography variant="body1" sx={{
                fontSize: 16,
                color: '#616161',
                lineHeight: 1.4
              }}>
                Upgrade your fishing storage! Enter current and desired sizes below.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{
          marginTop: 2,
          marginBottom: 2,
          width: '100%'
        }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="ini"
              label="Current Tackle Box Size"
              name="ini"
              autoFocus
              type="number"
              InputProps={{ 
                inputProps: { 
                  step: 5,
                  min: 20,
                  max: 1000 
                }
              }}
              value={ini}
              onChange={(e) => setIni(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="fin"
              label="Target Tackle Box Size"
              id="fin"
              type="number"
              InputProps={{ 
                inputProps: { 
                  step: 5,
                  min: 20,
                  max: 1000 
                }
              }}
              value={fin}
              onChange={(e) => setFin(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                }
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                mt: 3, 
                mb: 2,
                py: 1.5,
                borderRadius: '8px',
                backgroundColor: '#2e7d32',
                '&:hover': {
                  backgroundColor: '#1b5e20',
                  transform: 'translateY(-1px)'
                },
                transition: 'all 0.3s ease'
              }}
            >
              Calculate Materials
            </Button>

            {isError && (
              <Box bgcolor="#ffebee" p={2} borderRadius={2} mb={2}>
                <Typography variant="body1" color="error">
                  Invalid input! Please check:
                  <ul style={{ margin: '8px 0 0 20px' }}>
                    <li>Must use 5-unit increments</li>
                    <li>Minimum size: 20</li>
                    <li>Maximum size: 1000</li>
                    <li>Target must be larger than current size</li>
                  </ul>
                </Typography>
              </Box>
            )}

            {!isError && showImages && (
              <Box sx={{ 
                mt: 4, 
                padding: 3,
                backgroundColor: '#f8f9fa',
                borderRadius: 4,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
              }}>
                <Typography variant="h6" sx={{ 
                  mb: 2,
                  color: '#2e7d32',
                  fontWeight: 600,
                  textAlign: 'center'
                }}>
                  Required Materials
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                  {[
                    {img: 'https://static.wikia.nocookie.net/hayday/images/4/40/Nail.png', label: 'Nails'},
                    {img: 'https://static.wikia.nocookie.net/hayday/images/5/59/Screw.png', label: 'Screws'},
                    {img: 'https://static.wikia.nocookie.net/hayday/images/0/02/Wood_Panel.png', label: 'Wood Panels'}
                  ].map((item, index) => (
                    <Grid item key={index} xs={4} sx={{ textAlign: 'center' }}>
                      <Box sx={{
                        padding: 2,
                        backgroundColor: 'white',
                        borderRadius: 3,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05)',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-4px)'
                        }
                      }}>
                        <img 
                          src={item.img} 
                          alt={item.label} 
                          style={{ 
                            width: '80px', 
                            height: '80px', 
                            objectFit: 'contain' 
                          }} 
                        />
                        <Typography variant="body1" sx={{ 
                          mt: 1,
                          color: '#2e7d32',
                          fontWeight: 600
                        }}>
                          {quantity}
                        </Typography>
                        <Typography variant="caption" sx={{ 
                          color: '#757575',
                          fontSize: '0.75rem'
                        }}>
                          {item.label}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TackleBoxSizeCalculator;