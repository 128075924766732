import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Avatar, Paper } from "@mui/material";
import AdSense from '../components/googleAds';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const initialBuildings = [
  { value: "GroceryStore", label: "Grocery Store", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/7/7e/Grocery_Store.png" },
  { value: "Cinema", label: "Cinema", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/3/39/Cinema.png" },
  { value: "BedAndBreakfast", label: "Bed and Breakfast", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/8/88/Bed_and_Breakfast.png" },
  { value: "Diner", label: "Diner", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/1/19/Diner.png" },
  { value: "Spa", label: "Spa", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/e/eb/Spa.png" },
  { value: "GiftShop", label: "Gift Shop", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/5/5b/Gift_Shop.png" },
  { value: "BeachCafe", label: "Beach Cafe", maxLevel: 38, image: "https://static.wikia.nocookie.net/hayday/images/b/bf/Beach_Caf%C3%A9.png" },
  { value: "PersonalTrain", label: "Personal Train", maxLevel: 19, image: "https://static.wikia.nocookie.net/hayday/images/0/0a/Personal_Train.png" },
  { value: "TownHall", label: "Town Hall", maxLevel: 12, image: "https://static.wikia.nocookie.net/hayday/images/e/e6/Town_Hall.png" },
  { value: "TrainStation", label: "Train Station", maxLevel: 12, image: "https://static.wikia.nocookie.net/hayday/images/6/64/Train_Station.png" },
];

const defaultToolsNeeded = {
  GroceryStore: ["bolt", "duct tape", "stone block"],
  Cinema: ["hammer", "nail", "wood panel"],
  Diner: ["paint bucket", "plank", "screw"],
  BedAndBreakfast: ["brick", "hand drill", "paint bucket"],
  Spa: ["brick", "stone block", "tar bucket"],
  GiftShop: ["hammer", "stone block", "tar bucket"],
  BeachCafe: ["hammer", "hand drill", "tar bucket"],
  PersonalTrain: [
    "refined coal",
    "iron bar",
    "silver bar",
    "gold bar",
    "platinum bar",
  ],
  TownHall: ["hammer", "paint bucket", "stone block"],
  TrainStation: ["brick", "hand drill", "tar bucket"],
};


const TownToolsCalculator = () => {
  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: "/townToolsCalculator", title: "Town Tools Calculator Page Visit" });
  }, []);

  const classes = useStyles();
  const [building, setBuilding] = useState("");
  const [currentLevel, setCurrentLevel] = useState("");
  const [upgradeLevel, setUpgradeLevel] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [buildings, setBuildings] = useState(initialBuildings);
  const [images, setImages] = useState([]);
  const [isError, setIsError] = useState(false);
  const [toolsNeeded, setToolsNeeded] = useState({});

  // Update checkForErrors function
function checkForErrors(buildingList) {
  return buildingList.some(item => {
    const buildingData = initialBuildings.find(b => b.value === item.building);
    if (!buildingData) return true;
    
    const current = parseInt(item.currentLevel, 10);
    const upgrade = parseInt(item.upgradeLevel, 10);
    
    return (
      isNaN(current) ||
      isNaN(upgrade) ||
      current >= upgrade ||
      upgrade > buildingData.maxLevel ||
      current < 1
    );
  });
}

  function calculateToolsForBuildings(buildingList) {
    setToolsNeeded({});
    buildingList.forEach((item) => {
      const { building } = item;
      switch (building) {
        case "GroceryStore":
        case "Cinema":
        case "BedAndBreakfast":
        case "Diner":
        case "Spa":
        case "GiftShop":
        case "BeachCafe":
        case "TownHall":
        case "TrainStation":
          getToolsForType1(item);
          break;
        case "PersonalTrain":
          getToolsForType2(item);
          break;
        default:
          console.log(`No tools needed for ${building}`);
      }
    });
  }

  function getToolsForType1(item) {
    const { building, currentLevel, upgradeLevel } = item;
    const currentLevelNum = Number(currentLevel);
    const upgradeLevelNum = Number(upgradeLevel);
    const tools = defaultToolsNeeded[building];
    let totalTools = 0;
    for (
      let i = Number(currentLevelNum) + 1;
      i <= Number(upgradeLevelNum);
      i++
    ) {
      totalTools += i - 1;
    }
    setToolsNeeded((prevState) => {
      const updatedTools = { ...prevState };

      for (let i = 0; i < tools.length; i++) {
        const tool = tools[i];
        const count = totalTools;

        if (updatedTools.hasOwnProperty(tool)) {
          updatedTools[tool] += count;
        } else {
          updatedTools[tool] = count;
        }
      }

      return updatedTools;
    });
  }

  function getToolsForType2(item) {
    const { building, currentLevel, upgradeLevel } = item;
    const currentLevelNum = Number(currentLevel);
    const upgradeLevelNum = Number(upgradeLevel);

    let y = 0;
    let toolNeeded = "";
    const tools = {
      "refined coal": 0,
      "iron bar": 0,
    };

    for (
      let i = Number(currentLevelNum) + 1;
      i <= Number(upgradeLevelNum);
      i++
    ) {
      if (i === 2 || i === 3 || i === 4) {
        y = 5;
      } else if (i === 5 || i === 6 || i === 7) {
        y = 10;
      } else if (i === 8 || i === 9 || i === 10) {
        y = 15;
      } else if (i === 11 || i === 12 || i === 13) {
        y = 20;
      } else if (i === 14 || i === 15 || i === 16) {
        y = 25;
      } else if (i === 17 || i === 18 || i === 19) {
        y = 30;
      }

      if (i === 2 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17) {
        toolNeeded = "silver bar";
      } else if (
        i === 3 ||
        i === 6 ||
        i === 9 ||
        i === 12 ||
        i === 15 ||
        i === 18
      ) {
        toolNeeded = "gold bar";
      } else if (
        i === 4 ||
        i === 7 ||
        i === 10 ||
        i === 13 ||
        i === 16 ||
        i === 19
      ) {
        toolNeeded = "platinum bar";
      }

      tools["refined coal"] += y;
      tools["iron bar"] += y;
      if (tools.hasOwnProperty(toolNeeded)) {
        tools[toolNeeded] += y;
      } else {
        tools[toolNeeded] = y;
      }
    }

    setToolsNeeded((prevState) => {
      const updatedTools = { ...prevState };

      for (const [tool, count] of Object.entries(tools)) {
        if (updatedTools.hasOwnProperty(tool)) {
          updatedTools[tool] += count;
        } else {
          updatedTools[tool] = count;
        }
      }

      return updatedTools;
    });
  }

  useEffect(() => {
    const data = localStorage.getItem("images");
    if (data) {
      setImages(JSON.parse(data));
    } else {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  useEffect(() => {
    const hasError = checkForErrors(buildingList);
    setIsError(hasError);
    if (!hasError) {
      calculateToolsForBuildings(buildingList);
    }
  }, [buildingList]);

  const handleAddBuilding = (e) => {
    e.preventDefault();
    const buildingToAdd = {
      building: building,
      currentLevel: currentLevel,
      upgradeLevel: upgradeLevel,
    };
    setBuildingList([...buildingList, buildingToAdd]);
    setBuilding("");
    setCurrentLevel("");
    setUpgradeLevel("");
    setBuildings(buildings.filter((b) => b.value !== building));
  };

  const handleEditBuilding = (index) => {
    const buildingToEdit = buildingList[index];
    setBuilding(buildingToEdit.building);
    setCurrentLevel(buildingToEdit.currentLevel);
    setUpgradeLevel(buildingToEdit.upgradeLevel);
    setBuildingList(buildingList.filter((b, i) => i !== index));
    setBuildings([
      ...buildings,
      initialBuildings.find(b => b.value === buildingToEdit.building) // Preserve original data
    ]);
  };

  const handleDeleteBuilding = (index) => {
    const buildingToDelete = buildingList[index];
    setBuildingList(buildingList.filter((b, i) => i !== index));
    setBuildings([
      ...buildings,
      { value: buildingToDelete.building, label: buildingToDelete.building },
    ]);
  };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Hay Day Town Tools Calculator</title>
        <meta name="description" content="Optimize your town upgrades in Hay Day! Calculate exact material requirements for all town buildings with level-by-level breakdowns and visual guides." />
        <meta name="keywords" content="Hay Day town calculator, hay day building materials, hay day town upgrades, hay day resource planner" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(8px)',
          borderRadius: '16px',
          padding: '32px',
          marginTop: '40px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          marginBottom: 4
        }}
      >
        <CssBaseline />
        <AdSense />
        
    
<Box sx={{
  backgroundColor: '#f5f5f5',
  borderRadius: 4,
  padding: 3,
  marginBottom: 4,
  textAlign: 'center',
  border: '1px solid #e0e0e0'
}}>
  <Grid container spacing={2} alignItems="center">
    <Grid item xs={12} md={6}>
      <img 
        src="https://static.wikia.nocookie.net/hayday/images/e/e6/Town_Hall.png" 
        alt="Hay Day Town" 
        style={{ 
          width: '100%', 
          maxWidth: '200px', 
          height: 'auto',
          filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))'
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Typography variant="h5" sx={{
        fontSize: 28,
        fontWeight: 700,
        color: '#2e7d32',
        marginBottom: 1,
        fontFamily: '"Luckiest Guy", cursive'
      }}>
        Town Tools Calculator
      </Typography>
      <Typography variant="body1" sx={{
        fontSize: 16,
        color: '#616161',
        lineHeight: 1.4
      }}>
        Plan your town upgrades efficiently! Select buildings and levels to calculate required materials.
      </Typography>
    </Grid>
  </Grid>
</Box>

        <Box sx={{ width: '100%' }}>
        <Paper elevation={0} sx={{ 
  p: 3, 
  mb: 3,
  borderRadius: 3,
  backgroundColor: '#f8fafb',
  border: '1px solid #e0e0e0'
}}>
            <form onSubmit={handleAddBuilding}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Building</InputLabel>
                    <Select
                      value={building}
                      onChange={(e) => setBuilding(e.target.value)}
                      label="Building"
                      sx={{ borderRadius: '8px' }}
                    >
                      {buildings.map((b) => (
                        <MenuItem
                        key={b.value}
                        value={b.value}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          py: 1.5,
                          borderBottom: '1px solid #eee',
                          '&:hover': { backgroundColor: '#f5f5f5' }
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={b.image}
                          sx={{ width: 40, height: 40, borderRadius: 1 }}
                        />
                        <Box>
                          <Typography variant="subtitle1" fontWeight={600}>
                            {b.label}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            Max Level: {b.maxLevel}
                          </Typography>
                        </Box>
                      </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={6}>
                <TextField
  fullWidth
  label="Current Level"
  type="number"
  value={currentLevel}
  InputProps={{ 
    inputProps: { 
      min: 1, 
      max: initialBuildings.find(b => b.value === building)?.maxLevel || 38
    }
  }}
  onChange={(e) => setCurrentLevel(e.target.value)}
/>
                </Grid>
                
                <Grid item xs={6}>
                <TextField
  fullWidth
  label="Upgrade To"
  type="number"
  value={upgradeLevel}
  InputProps={{ 
    inputProps: { 
      min: 1, 
      max: initialBuildings.find(b => b.value === building)?.maxLevel || 38
    }
  }}
  onChange={(e) => setUpgradeLevel(e.target.value)}
/>
                </Grid>
                
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{
                      py: 1.5,
                      borderRadius: '8px',
                      backgroundColor: '#2e7d32',
                      '&:hover': {
                        backgroundColor: '#1b5e20',
                        transform: 'translateY(-1px)'
                      },
                      transition: 'all 0.3s ease'
                    }}
                  >
                    Add Building
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>

          {buildingList.length === 0 && (
  <Box sx={{
    p: 3,
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    borderRadius: 2
  }}>
    <Typography variant="body1" color="textSecondary">
      No buildings added yet. Select a building and levels to get started!
    </Typography>
  </Box>
)}

          {buildingList.length > 0 && (
            <Paper elevation={0} sx={{ p: 2, borderRadius: 3, mb: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, color: '#2e7d32' }}>
                Selected Buildings
              </Typography>
              <List>
                {buildingList.map((buildingItem, index) => {
                  const buildingData = initialBuildings.find(b => b.value === buildingItem.building);
                  return (
                    <Paper key={index} elevation={2} sx={{ mb: 1.5, borderRadius: 2 }}>
  <ListItem>
    <Avatar
      variant="square"
      src={buildingData?.image}
      sx={{ width: 50, height: 50, mr: 2, borderRadius: 1 }}
    />
    <ListItemText
      primary={
        <Typography variant="subtitle1" fontWeight={600}>
          {buildingData?.label}
        </Typography>
      }
      secondary={
        <Box component="span" sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="caption">
            Level: {buildingItem.currentLevel}
          </Typography>
          <Typography variant="caption">
            → Upgrade: {buildingItem.upgradeLevel}
          </Typography>
        </Box>
      }
    />
    <ListItemSecondaryAction sx={{ display: 'flex', gap: 1 }}>
      <IconButton onClick={() => handleEditBuilding(index)}>
        <Edit fontSize="small" />
      </IconButton>
      <IconButton onClick={() => handleDeleteBuilding(index)} color="error">
        <Delete fontSize="small" />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
</Paper>
                  );
                })}
              </List>
            </Paper>
          )}

{isError && (
  <Box sx={{
    p: 2,
    mb: 2,
    backgroundColor: '#fff3f3',
    borderRadius: 2,
    border: '1px solid #ffd4d4'
  }}>
    <Typography variant="body2" color="error" sx={{ fontWeight: 500 }}>
      ⚠️ Invalid Input Detected:
    </Typography>
    <Box component="ul" sx={{ 
      mt: 1, 
      pl: 2.5,
      '& li': { 
        fontSize: '0.875rem',
        lineHeight: 1.6,
        color: '#d32f2f'
      }
    }}>
      <li>Upgrade level must be higher than current level</li>
      <li>Levels cannot exceed building's maximum capacity</li>
      <li>All fields must contain valid numbers</li>
    </Box>
  </Box>
)}
          {!isError && Object.keys(toolsNeeded).length > 0 && (
            <Paper elevation={0} sx={{ p: 3, borderRadius: 4, backgroundColor: '#f8f9fa' }}>
              <Typography variant="h6" sx={{ 
                mb: 3,
                color: '#2e7d32',
                fontWeight: 600,
                textAlign: 'center'
              }}>
                Total Required Materials
              </Typography>
              <Box sx={{ 
  p: 2,
  backgroundColor: 'white',
  borderRadius: 3,
  border: '1px solid #e0e0e0'
}}>

<Grid container spacing={2}>
  {Object.entries(toolsNeeded).map(([tool, count]) => {
    const toolImage = images.find(img => img.name === tool)?.image;
    return (
      <Grid item xs={6} sm={4} key={tool}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 1.5,
          minWidth: 100,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 1,
          transition: 'all 0.2s',
          '&:hover': { transform: 'translateY(-2px)' }
        }}>
          <Avatar
            variant="square"
            src={toolImage}
            sx={{ 
              width: 50, 
              height: 50, 
              borderRadius: 1,
              mb: 1
            }}
          />
          <Typography 
            variant="body1" 
            fontWeight={600}
            sx={{ 
              fontSize: '1.1rem',
              lineHeight: 1.2,
              textAlign: 'center',
              mb: 0.5
            }}
          >
            {count}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              textAlign: 'center',
              lineHeight: 1.1,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxWidth: '80%'
            }}
          >
            {tool.split(' ').map(word => 
              word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')}
          </Typography>
        </Box>
      </Grid>
    );
  })}
</Grid>
</Box>
            </Paper>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TownToolsCalculator;
