import React, { useEffect } from 'react';
import { Container, createTheme, ThemeProvider, CssBaseline, Card, CardContent, CardActions, Button, Typography, Grid, Box, Avatar, keyframes } from '@mui/material';
import AdSense from '../components/googleAds';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';

// Add animation keyframes
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2d3436',
    },
    background: {
      default: 'rgba(255,255,255,0.2)'
    }
  },
  typography: {
    fontFamily: [
      '"Lilita One"',
      'cursive'
    ].join(','),
    h2: {
      fontFamily: '"Bangers", cursive',
      color: '#2d3436',
      textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
    }
  }
});

const pages = [
  {
    name: 'Goods List',
    urlLink: '/goodsList',
    description: 'Browse the list of goods available',
    image: 'https://static.wikia.nocookie.net/hayday/images/3/36/Goods.png'
  },
  {
    name: 'Barn Size Calculator',
    urlLink: '/barnSizeCalculator',
    description: 'Calculate tools needed to upgrade your Barn',
    image: 'https://static.wikia.nocookie.net/hayday/images/f/f8/Barn.png'
  },
  {
    name: 'Town Tools Calculator',
    urlLink: '/townToolsCalculator',
    description: 'Calculate tools for upgrading Town',
    image: 'https://static.wikia.nocookie.net/hayday/images/e/e6/Town_Hall.png'
  },
  {
    name: 'Silo Size Calculator',
    urlLink: '/siloSizeCalculator',
    description: 'Calculate tools needed to upgrade your Silo',
    image: 'https://static.wikia.nocookie.net/hayday/images/5/5f/Silo.png'
  },
  {
    name: 'Tackle Box Calculator',
    urlLink: '/tackleBoxSizeCalculator',
    description: 'Calculate tools for upgrading Tackle Box',
    image: 'https://static.wikia.nocookie.net/hayday/images/2/2c/Tackle_Box.png'
  },
  {
    name: 'Deco Cost Calculator',
    urlLink: '/decoCostCalculator',
    description: 'Calculate the cost of decorating your farm',
    image: 'https://static.wikia.nocookie.net/hayday/images/7/7a/Tractor.png'
  },
  {
    name: 'Price Calculator',
    urlLink: '/priceCalculator',
    description: 'Calculate prices for selling in marketplace',
    image: 'https://static.wikia.nocookie.net/hayday/images/f/f0/Coins.png'
  },
  {
    name: 'Production Buildings List',
    urlLink: '/productionBuildingsList',
    description: 'List of all production buildings',
    image: 'https://static.wikia.nocookie.net/hayday/images/4/47/Bakery.png'
  },
  {
    name: 'Food List Maker',
    urlLink: '/foodListMaker',
    description: 'Create a list of Foods you want to trade',
    image: 'https://static.wikia.nocookie.net/hayday/images/0/09/Roadside_Shop.png'
  },
  {
    name: 'Contact Us',
    urlLink: '/contactUs',
    description: 'Get in touch with us',
    image: 'https://static.wikia.nocookie.net/hayday/images/8/88/Mailbox.png'
  },
  {
    name: 'Download Hay Day',
    urlLink: '/download/hay-day-apk',
    description: 'Download Hay Day APK',
    image: 'https://play-lh.googleusercontent.com/Za8u0ChNK-3mZ3rnLcBirRBTzLYTdqaaAn63Ha9qKZiDp_bF_5u7OGfvWb1dg88WttKw'
  },
];

const glassCardStyle = {
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '15px',
  padding: '20px',
  backdropFilter: 'blur(12px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const Home = () => {
	useEffect(() => {
	  ReactGA.initialize("G-LZ55FW93G8");
	  ReactGA.send({ hitType: "pageview", page: "/home", title: "Home Page Visit" });
	}, []);
  
	return (
	  <>
		<Helmet>
		  <title>Hay Day Calculator - Ultimate Tools Collection</title>
		  <meta name="description" content="Maximize your Hay Day efficiency with specialized calculators and tools"/>
		  <link href="https://fonts.googleapis.com/css2?family=Bangers&family=Lilita+One&display=swap" rel="stylesheet"/>
		</Helmet>
  
		<Box sx={{
		  background: `linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)), url('https://wallpaperaccess.com/full/3022015.jpg')`,
		  minHeight: '100vh',
		  backgroundSize: 'cover',
		  backgroundPosition: 'center',
		  backgroundAttachment: 'fixed',
		  pt: 8
		}}>
		  <ThemeProvider theme={theme}>
			<Container maxWidth="lg">
			  <CssBaseline />
			  
			  <Box textAlign="center" mb={6}>
  <Typography variant="h2" gutterBottom sx={{
    animation: `${pulse} 2s infinite`,
    letterSpacing: '3px',
    color: '#ff3b3b',
    WebkitTextStroke: '1px #008080',
    fontSize: '4rem',
    fontFamily: '"Pacifico", cursive'
  }}>
    Hay Day Calculator
  </Typography>
  <Typography variant="h5" sx={{ 
    color: '#ff3b3b',
    fontFamily: '"Comic Sans MS", cursive',
    fontWeight: 800
  }}>
    Your Complete Farming Companion
  </Typography>
</Box>




  
			  <AdSense sx={{ my: 4 }} />
  
			  <Grid container spacing={4} justifyContent="center">
				{pages.map((page, index) => (
				  <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
					<Card style={glassCardStyle}>
					  <Box sx={{ 
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						height: '100%'
					  }}>
						<Avatar
						  variant="square"
						  src={page.image}
						  sx={{ 
							width: 120, 
							height: 120,
							mb: 2,
							borderRadius: '10px',
							boxShadow: 3
						  }}
						/>
						<CardContent sx={{ flexGrow: 1 }}>
						  <Typography variant="h5" component="h2" sx={{ 
							color: '#2d3436', 
							textAlign: 'center',
							mb: 1,
							fontFamily: '"Lilita One", cursive',
							fontWeight: 400
						  }}>
							{page.name}
						  </Typography>
						  <Typography variant="body2" sx={{ 
							color: '#2d3436', 
							textAlign: 'center',
							fontWeight: 500
						  }}>
							{page.description}
						  </Typography>
						</CardContent>
						<CardActions>
						  <Button
							variant="contained"
							component={Link}
							to={page.urlLink}
							sx={{
							  background: 'linear-gradient(45deg, #2ecc71 30%, #27ae60 90%)',
							  color: '#fff',
							  fontWeight: 'bold',
							  borderRadius: '25px',
							  px: 4,
							  transition: 'all 0.3s',
							  '&:hover': {
								transform: 'translateY(-3px)',
								boxShadow: '0 5px 15px rgba(46,204,113,0.4)',
								background: 'linear-gradient(45deg, #27ae60 30%, #2ecc71 90%)'
							  }
							}}
						  >
							Explore Now
						  </Button>
						</CardActions>
					  </Box>
					</Card>
				  </Grid>
				))}
			  </Grid>
  
			  <Box mt={8} py={4} textAlign="center" sx={{ 
				borderTop: '1px solid rgba(45,52,54,0.1)',
				color: '#2d3436'
			  }}>
				
			  </Box>
			</Container>
		  </ThemeProvider>
		</Box>
	  </>
	);
  };
  
export default Home;
