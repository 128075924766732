// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5-xGTF0WpaB5y6ZAvnMWgznESW3KWp6U",
  authDomain: "hay-day-calculator.firebaseapp.com",
  projectId: "hay-day-calculator",
  storageBucket: "hay-day-calculator.appspot.com",
  messagingSenderId: "541104206800",
  appId: "1:541104206800:web:7d1b9926e9ae6f572fbfd5",
  measurementId: "G-2MEZ4QMENN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
