import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import { Link } from 'react-router-dom'; // Add this import

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
}));

const Image = styled("img")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
}));

export default function ProductionBuildingMediaCard(props) {
  
  function capitalize(input) {  
        return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  } 
    
  


    function strtime(value){
        const sec = parseInt(value, 10);
        let hours   = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        if(hours===0 && minutes===0) return seconds + " sec";  
        if(hours===0 && seconds===0) return minutes + " mins";
        if(seconds===0 && minutes===0) return hours + " hrs";
        if(hours===0) return minutes + " mins" +  seconds + " sec";
        if(seconds===0) return hours + " hrs " + minutes + " mins";  
        return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
    }

    return (
      <Link 
      to={`/productionBuildingsList/${encodeURIComponent(props.name.toLowerCase().replace(/ /g, '-'))}`}
      style={{ 
        textDecoration: 'none',
        display: 'block',
        height: '100%'
      }}
    >
    <Card sx={{ 
      // textOverflow: 'ellipsis',
      overflow: 'visible',
      // whiteSpace: 'nowrap',
      // display: 'inherit',
      border: '1px solid red',
      position: 'relative',
      margin:'0 auto 30px auto',
      padding: '0px 10px',
      width: '300px',
      height: '470px',
      transform: 'translateY(20%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      backgroundColor: 'rgba(255,255,255,0.4)',
      borderRadius: '20px',
      boxShadow: '0 0 31 0 rgb(0 0 0 / 10%)',
      backdropFilter: 'blur(15px)',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      cursor: 'pointer',
        // transition: 'transform 0.1s, box-shadow 0.1s',
        // '&:hover': {
        //   transform: 'translateY(-1px)',
        //   boxShadow: '0 4px 10px 0 rgba(0,0,0,0.2)'
        // }
      }}>
      <CardMedia
        component="img"
        image={props.image}
        alt={props.name}
        sx={{
          objectFit: 'contain',
          width:100,
          height: 100
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
         {capitalize(props.name)}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          Level: {props.level[0]}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          Price: {props.price[0]}  
          <img src='https://static.wikia.nocookie.net/hayday/images/6/6d/Coin.png' alt='coin' style={{
          paddingTop:'2px',
          objectFit: 'contain',
          width:'25px',
          height: '25px'
        }}></img>
  
      </Typography>
        <Typography gutterBottom variant="h5" component="div">
          Time: {strtime(props.time)}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
          XP: {props.xpWhenBuilt}
        </Typography>
        {/* <Typography gutterBottom variant="h5" component="div">
          Source: {capitalize(props.source)}
        </Typography> */}

        {/* <Typography gutterBottom variant="h5" component="div">
  Needs:
  <div style={{ display: "flex" }}>
    {props.needs.length > 0 ? (
      props.needs.map((item, index) => (
        <Item key={index}>
          <Image src={props.goodsImages[item.name]} alt={item.name} />
          <Typography variant="subtitle1">{item.quantity}</Typography>
        </Item>
      ))
    ) : (
        <Typography gutterBottom variant="h5" component="div">
          -
        </Typography>


    )}
  </div>
</Typography> */}

        
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
    </Link>
  );
}


// name={building.name}
//           level={building.level}
//           price={building.price}
//           time={building.time}
//           xpWhenBuilt={building.xpWhenBuilt}
//           size={building.size}
//           image={building.image}
//           slots={building.slots}
//           goodsImages={goodsImages}