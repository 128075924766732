import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { 
  Container, Grid, Card, CardMedia, Chip, 
  Divider, CircularProgress, Typography, Button, Box,
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactGA from "react-ga4";
import AdSense from '../../components/googleAds';
import { HD_images } from '../../HD_images';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { suppliesData } from '../../supplies';

const theme = createTheme();

const capitalizeEachWord = (str) => {
  return str
    .replace(/-/g, ' ')
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// ===== FAQ Data & Accordion Component =====
const faqData = [
  {
    question: 'What are Expansion Permits?',
    answer:
      'Expansion permits are a type of supplies which let players unlock the 47 special expansion areas containing 29 unique decorations. They are unlocked at experience level 25. Unlike other expansion items, the permits are not stored in the barn.'
  },
  {
    question: 'How do I get Expansion Permits?',
    answer:
      'Unlike other expansion items, permits cannot be bought or sold. Permits can be obtained when players: Spin the Wheel of Fortune; Reach flag checkpoints 4 and 7 in derbies; Find them in Huge Boxes or in some of the Holiday Calendar Boxes on Greg\'s farm; Earn them during Special Boat Events; Buy them in Valley Shop or play the lucky bonus in valley Daily Quest until the 2nd round or above is reached; Complete checkpoints on either free or paid Farm Pass; or Purchase using real money in the diamond shop at the "Offer" section. At first, they could also be obtained by opening mystery boxes or reaching experience level thresholds, but those methods were later removed (with mystery boxes reintroducing them only in “huge” boxes).'
  },
  {
    question: 'Can I get more Expansion Permits after unlocking all areas?',
    answer:
      'Once players have the number of permits needed to unlock all areas, they will not get any more permits (unless new plots requiring permits are added in subsequent updates).'
  },
  {
    question: 'How many Expansion Permits are required?',
    answer:
      'A total of 643 permits are needed to clear all 50 expansion areas from the Summer 2021 update. After the Spring 2023 update, 1349 permits are needed to clear all available expansion areas.'
  },
  {
    question: 'What are some strategy tips for managing Expansion Permits?',
    answer:
      'You can see how many permits you have by tapping on a locked area on the south-east of your farm or by tapping your level star. Note that Expansion Permits only appear in derby prize columns 4 and 7. If you choose to spend 5 diamonds to reshuffle the derby prizes to earn Expansion Permits, be sure not to select a prize in columns 4 or 7 before shuffling since these are the only columns where permits can be awarded.'
  }
];

const faqJsonLd = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": faqData.map(faq => ({
    "@type": "Question",
    "name": faq.question,
    "acceptedAnswer": {
      "@type": "Answer",
      "text": faq.answer
    }
  }))
};

const FAQAccordion = () => (
  <Box sx={{ mt: 4 }}>
    <Typography variant="h4" component="h2" gutterBottom>
      Frequently Asked Questions
    </Typography>
    {faqData.map((faq, index) => (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{faq.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">{faq.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
);
// ===== End FAQ Section =====

const ExpansionPermitDetails = () => {
  const productionBuildingName = "expansion permit";
  const navigate = useNavigate();
  const location = useLocation();
  const [item, setItem] = useState(null);
  const [allGoods, setAllGoods] = useState([]);
  const [goodsImages, setGoodsImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: `/supplies/expansion-permit`, title: "Expansion Permit Details Page Visit" });

    const loadData = async () => {
      try {
        // Load goods data
        const goodsData = suppliesData;
        setAllGoods(suppliesData);
        setGoodsImages(HD_images);

        // Find item with hyphen handling
        const decodedName = productionBuildingName;
        const foundItem = goodsData.find(i => i.name.toLowerCase() === decodedName);
        setItem(foundItem || null);
      } catch (error) {
        console.error("Error loading data:", error);
        setItem(null);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const getIngredientImage = (ingredientName) => {
    const imageFromCache = goodsImages[ingredientName];
    if (imageFromCache) return imageFromCache;

    const foundItem = allGoods.find(item =>
      item.name.toLowerCase() === ingredientName.toLowerCase()
    );
    return foundItem?.image || 'https://via.placeholder.com/50';
  };

  const generateDescription = (item) => {
    const formattedName = capitalizeEachWord(item.name);
    return item.description || `${formattedName} is a production building unlocked at experience level ${item.level}.`;
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours > 0 && minutes > 0)
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''}`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    return `${seconds} seconds`;
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress size={60} thickness={4} />
      </Container>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${capitalizeEachWord(item.name)} - Hay Day Production Building Details`}</title>
        <meta 
          name="description" 
          content={`Detailed information about ${capitalizeEachWord(item.name)} building in Hay Day. Level ${item.level} goods, build time ${formatTime(item.time)}.`}
        />
        <meta 
          name="keywords" 
          content={`Hay Day ${capitalizeEachWord(item.name)}, ${capitalizeEachWord(item.name)} details, buildings, Hay Day items, Hay Day goods, ${capitalizeEachWord(item.name)} building, ${capitalizeEachWord(item.name)} food, ${capitalizeEachWord(item.name)} hay day food`}
        />
        <link rel="canonical" href={`https://haydaycalculator.shootingspeed.com/productionBuildingsList/${item.name.toLowerCase().replace(/ /g, '-')}`} />

        {/* Open Graph Tags */}
        <meta property="og:title" content={`${capitalizeEachWord(item.name)} - Hay Day Building`} />
        <meta property="og:description" content={`Learn all about ${capitalizeEachWord(item.name)} in Hay Day`} />
        <meta property="og:image" content={item.image} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${capitalizeEachWord(item.name)} - Hay Day Building`} />
        <meta name="twitter:description" content={`Complete guide for ${capitalizeEachWord(item.name)} in Hay Day`} />
        <meta name="twitter:image" content={item.image} />

        {/* Schema.org Product Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": item.name,
            "image": item.image,
            "description": `Hay Day Building: ${item.name}. Level ${item.level}, Buy for 0 coins.`,
            "brand": {
              "@type": "Brand",
              "name": "Hay Day"
            },
            "offers": {
              "@type": "Offer",
              "price": 0,
              "priceCurrency": "USD"
            }
          })}
        </script>

        {/* Schema.org FAQ Markup for SEO */}
        <script type="application/ld+json">
          {JSON.stringify(faqJsonLd)}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AdSense />
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Button 
            startIcon={<ArrowBackIcon />}
            onClick={() => {
                if (window.history.length > 2) {
                  navigate(-1);
                } else {
                  navigate('/home');
                }
              }}
            sx={{ 
              mb: 4,
              borderRadius: '8px',
              px: 3,
              py: 1,
              boxShadow: 1,
              '&:hover': { boxShadow: 3 }
            }}
          >
            Back
          </Button>

          <Card sx={{ borderRadius: '16px', boxShadow: 3, overflow: 'hidden' }}>
            <Grid container>
              {/* Image Section */}
              <Grid item xs={12} md={4} sx={{ 
                backgroundColor: '#f8f9fa',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4
              }}>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.name}
                  sx={{ width: '100%', maxWidth: 300, height: 'auto', borderRadius: '8px' }}
                />
              </Grid>

              {/* Details Section */}
              <Grid item xs={12} md={8} sx={{ p: 4 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
                    {capitalizeEachWord(item.name)}
                  </Typography>
                  <Chip 
                    label={`Level ${item.level}`}
                    color="primary"
                    sx={{ fontSize: '1rem', px: 2, py: 1, borderRadius: '4px' }}
                  />
                </Box>
                <Divider sx={{ my: 3 }} />
                <Grid container spacing={4}>
                  {/* Production Info */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                      Production Details
                    </Typography>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="body1" paragraph sx={{ 
                      backgroundColor: '#f8f9fa',
                      borderRadius: '8px',
                      p: 3,
                      lineHeight: 1.6
                    }}>
                      {generateDescription(item)}
                    </Typography>
                  </Grid>
                  {/* Additional details sections can be added here */}
                </Grid>
              </Grid>
            </Grid>

            {/* Divider between details and FAQs */}
            <Divider sx={{ my: 3 }} />

            {/* FAQ Accordion Section inside the Card */}
            <Box sx={{ p: 4 }}>
              <FAQAccordion />
            </Box>
          </Card>
        </Container>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default ExpansionPermitDetails;
