import ProductionBuildingMediaCard from './card';
import { Link } from 'react-router-dom';
import { Grid } from "@mui/material";
const DispProductionBuildingsData = ({productionBuildings, goodsImages}) => {

    if(productionBuildings.length === 0) return <div> Loading Data</div>;
      return(
        <Grid container justifyContent={'space-evenly'} >
  {productionBuildings.map((building, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
         <Link 
            to={`/productionBuildingsList/${encodeURIComponent(building.name.toLowerCase().replace(/ /g, '-'))}`}
            style={{ textDecoration: 'none' }}
          ></Link>
        <ProductionBuildingMediaCard
  sx={{
    m: 1,
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: 3
    }
  }}
          name={building.name}
          level={building.level}
          price={building.price}
          time={building.time}
          xpWhenBuilt={building.xpWhenBuilt}
          size={building.size}
          image={building.image}
          slots={building.slots}
          goodsImages={goodsImages}
        />
      </Grid>
    )
  })}
</Grid>

      )
};

export default DispProductionBuildingsData;
