import React, { useEffect } from 'react';
import { Container, Card, CardContent, Typography, Button, Box, Grid, Divider, CardMedia } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";
import AdSense from '../components/googleAds';

const theme = createTheme();

const DownloadHayDay = () => {
  // Define meta keywords (numbers removed)
  const metaKeywords = [
    "hay day crack",
    "hay day mod apk",
    "hack hay day",
    "hay day apk",
    "apk",
    "game hay day",
    "game hay",
    "hay doi day",
    "hay day download",
    "hayday",
    "hay day android",
    "mod hay day",
    "cheats hay day",
    "facebook hay day",
    "hay day pc",
    "hay day apk mod",
    "hay day farm",
    "hay day level",
    "phim hay",
    "hay day cheat",
    "hay day online",
    "nhac hay",
    "hay day games",
    "hay day скачать",
    "tai hay day",
    "download hay day apk",
    "hay day hile",
    "hay day apk",
    "apk",
    "game hay day",
    "game hay",
    "hay doi day",
    "hay day android",
    "mod hay day",
    "facebook hay day",
    "hay day pc",
    "hay day level",
    "phim hay",
    "hay day online",
    "nhac hay",
    "hay day скачать",
    "tai hay day",
    "hoat hinh",
    "hay day diamonds",
    "hay day app",
    "hay day apk hack",
    "hay day indir",
    "hay day game download",
    "tom hay day",
    "hay day hack android",
    "phim hay doi day",
    "wiki hay day",
    "buy bems",
    "free bems",
    "buy sell hay day accounts",
    "buy hay day",
    "trade bems",
    "buy bolts tapes planks"
  ].join(', ');

  useEffect(() => {
    // Initialize Google Analytics and send pageview
    ReactGA.initialize("G-LZ55FW93G8");
    ReactGA.send({ hitType: "pageview", page: `/download`, title: "Download Hay Day App Page Visit" });
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Download Hay Day App - Farm, Grow & Enjoy</title>
        <meta 
          name="description" 
          content="Download the Hay Day app to experience the ultimate farming game. Manage your own farm, cultivate crops, trade goods with neighbors, and join a vibrant community. Get the app for Android and iOS now!" 
        />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href="https://haydaycalculator.shootingspeed.com/download" />
        <meta property="og:title" content="Download Hay Day App - Farm, Grow & Enjoy" />
        <meta property="og:description" content="Download the Hay Day app to experience the ultimate farming game. Manage your own farm, cultivate crops, trade goods, and join a vibrant community. Available for Android and iOS." />
        <meta property="og:url" content="https://haydaycalculator.shootingspeed.com/download" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Google AdSense component */}
        <AdSense />
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Card sx={{ borderRadius: '16px', boxShadow: 3, p: 4 }}>
            <CardContent>
              <Typography variant="h2" component="h1" align="center" gutterBottom>
                Download Hay Day App
              </Typography>
              <Grid container spacing={4} alignItems="center">
                {/* App Image */}
                <Grid item xs={12} md={6}>
                  <CardMedia
                    component="img"
                    image="https://play-lh.googleusercontent.com/Za8u0ChNK-3mZ3rnLcBirRBTzLYTdqaaAn63Ha9qKZiDp_bF_5u7OGfvWb1dg88WttKw"
                    alt="Hay Day App"
                    sx={{ borderRadius: '8px', maxWidth: '100%', height: 'auto' }}
                  />
                </Grid>
                {/* App Description and Download Buttons */}
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" align="center" sx={{ mb: 4 }}>
                    Welcome to Hay Day—the ultimate farming game where you manage your own farm, produce crops, and trade goods with neighbors. Enjoy endless fun and join millions of players worldwide.
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        size="large"
                        startIcon={<AndroidIcon />}
                        href="https://drive.google.com/file/d/1IF0rSDWkZVudDthkv9NzzIcYCpcHF30w/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download for Android
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button 
                        variant="contained" 
                        color="secondary" 
                        size="large"
                        startIcon={<AppleIcon />}
                        href="https://apps.apple.com/us/app/hay-day/id506627515"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download for iOS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default DownloadHayDay;
