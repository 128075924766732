import { db } from '../config/firestore';
import { doc, setDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

export const createUserDocument = async (user) => {
  if (!user) return;
  
  const userRef = doc(db, 'users', user.uid);
  await setDoc(userRef, {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    photoURL: user.photoURL,
    phoneNumber: user.phoneNumber,
    providerData: user.providerData.map(provider => ({
      providerId: provider.providerId,
      uid: provider.uid,
      displayName: provider.displayName,
      email: provider.email,
      phoneNumber: provider.phoneNumber,
      photoURL: provider.photoURL
    })),
    metadata: {
      creationTime: user.metadata.creationTime,
      lastSignInTime: user.metadata.lastSignInTime
    },
    emailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    lastLogin: serverTimestamp()
  }, { merge: true });
};

export const createLogEntry = async (userId, action) => {
  await addDoc(collection(db, 'userLogs'), {
    userId,
    action,
    timestamp: serverTimestamp(),
    ipAddress: null // You can add IP tracking if needed
  });
};