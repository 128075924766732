import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { 
  Container, Grid, Card, CardContent, CardMedia, Chip, 
  Divider, CircularProgress, Typography, Button, Box
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Add this import
import ReactGA from "react-ga4";
import { Constants } from '../config/constants';
import AdSense from '../components/googleAds';
import { goods } from '../goods';
import { HD_images } from '../HD_images';
import { getGoods } from "../api/fetch"; // Add this import
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { productionBuildingsData } from "../productionBuildings";

const theme = createTheme();

const capitalizeEachWord = (str) => {
  return str
    .replace(/-/g, ' ') // Replace hyphens with spaces first
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ProductionBuildingDetails = () => {
  const { productionBuildingName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [item, setItem] = useState(null);
  const [allGoods, setAllGoods] = useState([]);
  const [goodsImages, setGoodsImages] = useState({});
  const [loading, setLoading] = useState(true);

    useEffect(() => {
        ReactGA.initialize("G-LZ55FW93G8");
        ReactGA.send({ hitType: "pageview", page: `/productionBuildingsList/${productionBuildingName}`, title: "Production Building Details Page Visit" });

        const loadData = async () => {
          try {
              // Load goods data
              let goodsData=productionBuildingsData;
              setAllGoods(goodsData);
              setGoodsImages(HD_images);

              // Find item with hyphen handling
              const decodedName = decodeURIComponent(productionBuildingName)
                  .replace(/-/g, ' ') // Convert hyphens back to spaces for search
                  .toLowerCase();
                  
              const foundItem = goodsData.find(i => 
                  i.name.toLowerCase() === decodedName
              );
              
              setItem(foundItem || null);
          } catch (error) {
              console.error("Error loading data:", error);
              setItem(null);
          } finally {
              setLoading(false);
          }
      };

      loadData();
  }, [productionBuildingName, location.state]);

  const getIngredientImage = (ingredientName) => {
    // First check goodsImages, then allGoods
    const imageFromCache = goodsImages[ingredientName];
    if(imageFromCache) return imageFromCache;

    const foundItem = allGoods.find(item => 
        item.name.toLowerCase() === ingredientName.toLowerCase()
    );
    
    return foundItem?.image || 'https://via.placeholder.com/50';
};

const generateDescription = (item) => {
  const formattedName = capitalizeEachWord(item.name);
  const timeString = formatTime(item.time);

  return `${
    item.description || `${formattedName} Is A Production Building Unlocked At Experience Level ${item.level[0]}. ` +
    `It Takes ${timeString} To Build. ` +
    `Each Unit Gives Players ${item.xpWhenBuilt} Experience Points. ` +
    `The Price is ${item.price[0]} Coins.`
  }`;
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  if(hours > 0 && minutes > 0) return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
  if(hours > 0) return `${hours} hour${hours > 1 ? 's' : ''}`;
  if(minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  return `${seconds} seconds`;
};

    if (loading) {
        return (
            <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress size={60} thickness={4} />
            </Container>
        );
    }

    if (!item) {
        return (
            <Container sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h4" color="error" gutterBottom>
                    Item Not Found
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => {
                        if (window.history.length > 2) {
                          navigate(-1);
                        } else {
                          navigate('/home');
                        }
                      }}
                    sx={{ mt: 2 }}
                >
                    Go Back
                </Button>
            </Container>
        );
    }

    return (
      <HelmetProvider>
      {/* ... existing code ... */}
      <Helmet>
        <title>{`${capitalizeEachWord(item.name)} - Hay Day Production Building Details`}</title>
        <meta 
          name="description" 
          content={`Detailed information about ${capitalizeEachWord(item.name)} building in Hay Day. Level ${item.level[0]} Goods, build time ${formatTime(item.time)}, purchase for ${item.price[0]} coins.`}
        />
        <meta 
          name="keywords" 
          content={`Hay Day ${capitalizeEachWord(item.name)}, ${capitalizeEachWord(item.name)} details, buildings, Hay Day items, Hay Day goods,  ${capitalizeEachWord(item.name)},  ${capitalizeEachWord(item.name)} building,  ${capitalizeEachWord(item.name)} food,  ${capitalizeEachWord(item.name)} hay day food, ${capitalizeEachWord(item.name)} hay day`}
        />
        <link rel="canonical" href={`https://haydaycalculator.shootingspeed.com/productionBuildingsList/${item.name.toLowerCase().replace(/ /g, '-')}`} />
        
        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content={`${capitalizeEachWord(item.name)} - Hay Day Building`} />
        <meta property="og:description" content={`Learn all about ${capitalizeEachWord(item.name)} in Hay Day`} />
        <meta property="og:image" content={item.image} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${capitalizeEachWord(item.name)} - Hay Day Building`} />
        <meta name="twitter:description" content={`Complete guide for ${capitalizeEachWord(item.name)} in Hay Day`} />
        <meta name="twitter:image" content={item.image} />

        <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Product",
      "name": item.name,
      "image": item.image,
      "description": `Hay Day Building: ${item.name}. Level ${item.level}, Build time: ${formatTime(item.time)}, Buy for ${item.price[0]} coins.`,
      "brand": {
        "@type": "Brand",
        "name": "Hay Day"
      },
      "offers": {
        "@type": "Offer",
        "price": item.price[0],
        "priceCurrency": "USD"
      }
    })}
  </script>
      </Helmet>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AdSense />
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Button 
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                        if (window.history.length > 2) {
                          navigate(-1);
                        } else {
                          navigate('/home');
                        }
                      }}
                    sx={{ 
                        mb: 4,
                        borderRadius: '8px',
                        px: 3,
                        py: 1,
                        boxShadow: 1,
                        '&:hover': {
                            boxShadow: 3
                        }
                    }}
                >
                    Back
                </Button>

                <Card sx={{ 
                    borderRadius: '16px',
                    boxShadow: 3,
                    overflow: 'hidden'
                }}>
                    <Grid container>
                        {/* Image Section */}
                        <Grid item xs={12} md={4} sx={{ 
                            backgroundColor: '#f8f9fa',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 4
                        }}>
                            <CardMedia
                                component="img"
                                image={item.image}
                                alt={item.name}
                                sx={{ 
                                    width: '100%', 
                                    maxWidth: 300,
                                    height: 'auto',
                                    borderRadius: '8px'
                                }}
                            />
                        </Grid>

                        {/* Details Section */}
                        <Grid item xs={12} md={8} sx={{ p: 4 }}>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
                                {capitalizeEachWord(item.name)}
                                </Typography>
                                <Chip 
                                    label={`Level ${item.level[0]}`}
                                    color="primary"
                                    sx={{ 
                                        fontSize: '1rem',
                                        px: 2,
                                        py: 1,
                                        borderRadius: '4px'
                                    }}
                                />
                            </Box>

                            <Divider sx={{ my: 3 }} />

                            <Grid container spacing={4}>
                                {/* Production Info */}
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
                                        Production Details
                                    </Typography>
                                    <Divider sx={{ my: 3 }} />

<Typography variant="body1" paragraph sx={{ 
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  p: 3,
  lineHeight: 1.6,
  fontStyle: 'normal'
}}>
  {generateDescription(item)}
</Typography>

                                    <DetailItem label="⏳ Production Time" value={formatTime(item.time)} />
                                    <DetailItem label="💰 Buy Price" value={`${item.price[0]} coins`} />
                                    <DetailItem label="⭐ XP Earned" value={item.xpWhenBuilt} />
                                   
                                   
                                </Grid>

                                {/* Required Ingredients */}
                                
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </ThemeProvider>
        </HelmetProvider>
    );
};

const DetailItem = ({ label, value }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>{label}:</Typography>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>{value}</Typography>
    </Box>
);

const IngredientCard = ({ name, quantity, image }) => (
    <Card sx={{ 
        display: 'flex', 
        alignItems: 'center',
        p: 2,
        borderRadius: '8px',
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: 2
        }
    }}>
        <CardMedia
            component="img"
            image={image}
            alt={name}
            sx={{ 
                width: 50, 
                height: 50,
                objectFit: 'contain',
                mr: 2
            }}
        />
        <Box>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>{capitalizeEachWord(name)}</Typography>
            <Typography variant="body2" color="text.secondary">
                Quantity: {quantity}
            </Typography>
        </Box>
    </Card>
);

export default ProductionBuildingDetails;