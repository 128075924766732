import MediaCard from "../Card/card";
import { Link } from 'react-router-dom';
import { Grid } from "@mui/material";
const DispData = ({goods, goodsImages}) => {

    if(goods.length === 0) return <div> Loading Data</div>;
      return(
        <Grid container justifyContent={'space-evenly'} >
  {goods.map((good, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
         <Link 
            to={`/goodsList/${encodeURIComponent(good.name.toLowerCase().replace(/ /g, '-'))}`}
            style={{ textDecoration: 'none' }}
          ></Link>
        <MediaCard
  sx={{
    m: 1,
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: 3
    }
  }}
          name={good.name}
          level={good.level}
          price={good.price}
          time={good.time}
          xp={good.xp}
          source={good.source}
          image={good.image}
          needs={good.needs}
          goodsImages={goodsImages}
        />
      </Grid>
    )
  })}
</Grid>

      )
};

export default DispData;
